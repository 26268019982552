import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Settings } from '../../config/settings.service';
import { CustomPageComponent } from './custom-page/custom-page.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../config/settings.service";
var CustomHomepage = /** @class */ (function () {
    function CustomHomepage(router, settings) {
        this.router = router;
        this.settings = settings;
        this.defaultComponents = [
            { name: 'login', routeConfig: { redirectTo: '/login', pathMatch: 'full' } },
            { name: 'register', routeConfig: { redirectTo: '/register', pathMatch: 'full' } },
        ];
        this.menuCategories = [
            { name: 'Custom Pages', route: { component: CustomPageComponent } },
        ];
    }
    CustomHomepage.prototype.select = function (custom) {
        if (custom === void 0) { custom = {}; }
        this.defaultComponents = this.defaultComponents.concat(custom.routes || []);
        this.menuCategories = this.menuCategories.concat(custom.menuCategories);
        var type = this.settings.get('homepage.type') || 'default', value = this.settings.get('homepage.value');
        if (type === 'default' || type == null) {
            return;
        }
        else if (type === 'component') {
            return this.setComponentAsHomepage(value);
        }
        else {
            var category = this.menuCategories.find(function (c) { return c.name === type; });
            if (category) {
                var route = tslib_1.__assign({}, category.route, { data: { id: value } });
                this.addRoute(route);
            }
        }
    };
    CustomHomepage.prototype.isOnlyForGuests = function () {
        var type = this.settings.get('homepage.type', 'default'), value = this.settings.get('homepage.value');
        return type === 'component' && (value === 'login' || value === 'register');
    };
    CustomHomepage.prototype.getComponents = function () {
        return this.defaultComponents;
    };
    CustomHomepage.prototype.setComponentAsHomepage = function (name) {
        var page = this.defaultComponents.find(function (comp) { return comp.name === name; });
        if (!page)
            return;
        this.addRoute(tslib_1.__assign({}, page.routeConfig), page.makeRoot);
    };
    CustomHomepage.prototype.addRoute = function (route, makeRoot) {
        if (makeRoot === void 0) { makeRoot = false; }
        var parent = makeRoot ? null : this.getParentHomeRoute();
        route = this.prepareRoute(route);
        // use child routes if parent exists, otherwise use base router config
        var routes = parent ? parent.children : this.router.config;
        // remove already existing home route
        var i = routes.findIndex(function (r) { return r.path === ''; });
        // add new route specified by user
        if (i > -1) {
            routes[i] = route;
        }
        else {
            routes.unshift(route);
        }
    };
    CustomHomepage.prototype.getParentHomeRoute = function () {
        return this.router.config.find(function (route) {
            return route.data && route.data.parentHomeRoute;
        });
    };
    CustomHomepage.prototype.prepareRoute = function (route) {
        route.path = '';
        if (!route.data)
            route.data = {};
        route.data.name = 'home';
        return route;
    };
    CustomHomepage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomHomepage_Factory() { return new CustomHomepage(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Settings)); }, token: CustomHomepage, providedIn: "root" });
    return CustomHomepage;
}());
export { CustomHomepage };
