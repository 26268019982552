import { map } from 'rxjs/operators';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
var Users = /** @class */ (function () {
    function Users(http) {
        this.http = http;
    }
    Users.prototype.get = function (id, params) {
        return this.http.get("users/" + id, params);
    };
    Users.prototype.getAll = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.get('users', params).pipe(map(function (response) { return response['pagination']['data']; }));
    };
    Users.prototype.create = function (payload) {
        return this.http.post('users', payload);
    };
    Users.prototype.update = function (id, payload) {
        return this.http.put('users/' + id, payload);
    };
    Users.prototype.changePassword = function (id, payload) {
        return this.http.post('users/' + id + '/password/change', payload);
    };
    Users.prototype.attachRoles = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/roles/attach', payload);
    };
    Users.prototype.detachRoles = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/roles/detach', payload);
    };
    Users.prototype.addPermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/start', payload);
    };
    Users.prototype.removePermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/remove', payload);
    };
    Users.prototype.uploadAvatar = function (id, files) {
        var payload = new FormData();
        payload.append('file', files[0].native);
        return this.http.post('users/' + id + '/avatar', payload);
    };
    Users.prototype.deleteAvatar = function (id) {
        return this.http.delete('users/' + id + '/avatar');
    };
    Users.prototype.delete = function (ids) {
        return this.http.delete("users/" + ids);
    };
    //
    /**
     * Sync specified user tags.
     */
    Users.prototype.syncTags = function (id, payload) {
        return this.http.post('users/' + id + '/tags/sync', payload);
    };
    /**
     * Update details about user.
     */
    Users.prototype.updateDetails = function (id, payload) {
        return this.http.put('users/' + id + '/details', payload);
    };
    /**
     * Add secondary email to specified user.
     */
    Users.prototype.addEmail = function (id, payload) {
        return this.http.post('users/' + id + '/emails/attach', payload);
    };
    /**
     * Remove secondary email from specified user.
     */
    Users.prototype.removeEmail = function (id, payload) {
        return this.http.post('users/' + id + '/emails/detach', payload);
    };
    Users.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Users_Factory() { return new Users(i0.ɵɵinject(i1.AppHttpClient)); }, token: Users, providedIn: "root" });
    return Users;
}());
export { Users };
