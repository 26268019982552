import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NewsService } from '../news.service';
import { finalize } from 'rxjs/operators';
import { InfiniteScroll } from '@common/core/ui/infinite-scroll/infinite.scroll';
var NewsIndexComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewsIndexComponent, _super);
    function NewsIndexComponent(news, zone) {
        var _this = _super.call(this) || this;
        _this.news = news;
        _this.zone = zone;
        _this.loading$ = new BehaviorSubject(false);
        _this.articles$ = new BehaviorSubject(null);
        return _this;
    }
    NewsIndexComponent.prototype.ngOnInit = function () {
        this.loadArticles();
        _super.prototype.ngOnInit.call(this);
    };
    NewsIndexComponent.prototype.loadArticles = function () {
        var _this = this;
        this.loading$.next(true);
        var page = this.articles$.value ? (this.articles$.value.current_page + 1) : 1;
        this.news.getAll({ perPage: 10, page: page, stripHtml: true })
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            if (_this.articles$.value) {
                response.pagination.data = _this.articles$.value.data.concat(response.pagination.data);
            }
            _this.articles$.next(response.pagination);
        });
    };
    NewsIndexComponent.prototype.loadMoreItems = function () {
        this.loadArticles();
    };
    NewsIndexComponent.prototype.canLoadMore = function () {
        return this.articles$.value && (this.articles$.value.current_page < this.articles$.value.last_page);
    };
    NewsIndexComponent.prototype.isLoading = function () {
        return this.loading$.value;
    };
    return NewsIndexComponent;
}(InfiniteScroll));
export { NewsIndexComponent };
