import * as tslib_1 from "tslib";
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { randomString } from '../../core/utils/random-string';
var UploadQueueItem = /** @class */ (function () {
    function UploadQueueItem(file) {
        this.id = randomString();
        this.canceled$ = new Subject();
        // backend response once file is uploaded
        this.uploadedResponse$ = new ReplaySubject(1);
        // meta information (will change)
        this.meta$ = new BehaviorSubject({});
        // custom data that can be attached to queue item
        this.customData = {};
        this.uploadedFile = file;
    }
    Object.defineProperty(UploadQueueItem.prototype, "completed", {
        get: function () {
            return this.meta$.value.progress === 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadQueueItem.prototype, "pending", {
        get: function () {
            return this.meta$.value.progress == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadQueueItem.prototype, "hasError", {
        get: function () {
            return this.meta$.value.error != null;
        },
        enumerable: true,
        configurable: true
    });
    UploadQueueItem.prototype.update = function (data) {
        this.meta$.next(tslib_1.__assign({}, this.meta$.value, data));
    };
    UploadQueueItem.prototype.cancel = function () {
        // cancelling before finalizing might cause infinite loop
        this.finalize();
        this.canceled$.next(true);
    };
    UploadQueueItem.prototype.complete = function () {
        this.update({ progress: 100 });
        this.finalize();
    };
    UploadQueueItem.prototype.finalize = function () {
        this.uploadedResponse$.complete();
        this.canceled$.complete();
        this.meta$.complete();
    };
    UploadQueueItem.prototype.addError = function (message) {
        if (message === void 0) { message = ''; }
        this.update({ error: message });
    };
    return UploadQueueItem;
}());
export { UploadQueueItem };
