import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var CurrentUser = /** @class */ (function () {
    function CurrentUser() {
        this.model$ = new BehaviorSubject(null);
        this.isLoggedIn$ = this.model$.pipe(map(function (u) { return !!u.id; }));
        this.permissions = {};
    }
    CurrentUser.prototype.get = function (prop) {
        return this.model$.value && this.model$.value[prop];
    };
    CurrentUser.prototype.getModel = function () {
        return tslib_1.__assign({}, this.model$.value);
    };
    CurrentUser.prototype.set = function (key, value) {
        var _a;
        this.model$.next(tslib_1.__assign({}, this.model$.value, (_a = {}, _a[key] = value, _a)));
    };
    CurrentUser.prototype.assignCurrent = function (model) {
        if (!model) {
            // guest model
            model = { roles: [this.guestsRole], permissions: this.guestsRole.permissions };
        }
        this.setPermissions(model);
        this.model$.next(model);
    };
    CurrentUser.prototype.hasPermissions = function (permissions) {
        var _this = this;
        return permissions.filter(function (permission) {
            return !_this.hasPermission(permission);
        }).length === 0;
    };
    CurrentUser.prototype.hasPermission = function (permission) {
        return !!this.permissions['admin'] || !!this.permissions[permission];
    };
    CurrentUser.prototype.hasRole = function (role) {
        return this.model$.value.roles && !!this.model$.value.roles.find(function (r) { return r.name === role; });
    };
    CurrentUser.prototype.getRestrictionValue = function (permissionName, restrictionName) {
        var permission = this.permissions[permissionName];
        var restrictionValue = null;
        if (permission) {
            var restriction = permission.restrictions.find(function (r) { return r.name === restrictionName; });
            restrictionValue = restriction ? restriction.value : null;
        }
        return restrictionValue;
    };
    CurrentUser.prototype.isLoggedIn = function () {
        return this.get('id') > 0;
    };
    /**
     * Check if user subscription is active, on trial, or on grace period.
     */
    CurrentUser.prototype.isSubscribed = function () {
        if (!this.model$.value.subscriptions)
            return false;
        return this.model$.value.subscriptions.find(function (sub) { return sub.valid; }) !== undefined;
    };
    /**
     * Check if user subscription is active
     */
    CurrentUser.prototype.subscriptionIsActive = function () {
        return this.isSubscribed() && !this.onTrial();
    };
    CurrentUser.prototype.onTrial = function () {
        var sub = this.getSubscription();
        return sub && sub.on_trial;
    };
    CurrentUser.prototype.onGracePeriod = function () {
        var sub = this.getSubscription();
        return sub && sub.on_grace_period;
    };
    CurrentUser.prototype.getSubscription = function (filters) {
        if (filters === void 0) { filters = {}; }
        if (!this.isSubscribed())
            return null;
        var subs = this.model$.value.subscriptions.slice();
        if (filters.gateway) {
            subs = subs.filter(function (sub) { return sub.gateway === filters.gateway; });
        }
        if (filters.planId) {
            subs = subs.filter(function (sub) { return sub.plan_id === filters.planId; });
        }
        return subs[0];
    };
    CurrentUser.prototype.setSubscription = function (subscription) {
        var i = this.model$.value.subscriptions.findIndex(function (sub) { return sub.id === subscription.id; });
        if (i > -1) {
            this.model$.value.subscriptions[i] = subscription;
        }
        else {
            this.model$.value.subscriptions.push(subscription);
        }
    };
    CurrentUser.prototype.isAdmin = function () {
        return this.hasPermission('admin');
    };
    CurrentUser.prototype.init = function (params) {
        this.guestsRole = params.guestsRole;
        this.assignCurrent(params.user);
    };
    CurrentUser.prototype.setPermissions = function (model) {
        var _this = this;
        this.permissions = {};
        (model.permissions || []).forEach(function (permission) {
            _this.permissions[permission.name] = permission;
        });
    };
    CurrentUser.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentUser_Factory() { return new CurrentUser(); }, token: CurrentUser, providedIn: "root" });
    return CurrentUser;
}());
export { CurrentUser };
