import { Router } from '@angular/router';
import { RequestExtraCredentialsModalComponent } from './request-extra-credentials-modal/request-extra-credentials-modal.component';
import { CurrentUser } from './current-user';
import { Settings } from '../core/config/settings.service';
import { Toast } from '../core/ui/toast.service';
import { AuthService } from './auth.service';
import { Translations } from '../core/translations/translations.service';
import { Modal } from '../core/ui/dialogs/modal.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
import * as i2 from "./current-user";
import * as i3 from "@angular/router";
import * as i4 from "../core/config/settings.service";
import * as i5 from "../core/ui/toast.service";
import * as i6 from "./auth.service";
import * as i7 from "../core/translations/translations.service";
import * as i8 from "../core/ui/dialogs/modal.service";
var SocialAuthService = /** @class */ (function () {
    function SocialAuthService(httpClient, currentUser, router, settings, toast, auth, i18n, modal) {
        this.httpClient = httpClient;
        this.currentUser = currentUser;
        this.router = router;
        this.settings = settings;
        this.toast = toast;
        this.auth = auth;
        this.i18n = i18n;
        this.modal = modal;
        /**
         * Social login popup window height.
         */
        this.windowHeight = 550;
        /**
         * Social login popup window width.
         */
        this.windowWidth = 650;
        this.listenForMessageFromPopup();
    }
    /**
     * Log user in with specified social account.
     */
    SocialAuthService.prototype.loginWith = function (serviceName) {
        return this.openNewSocialAuthWindow('secure/auth/social/' + serviceName + '/login');
    };
    /**
     * Connect specified social account to current user.
     */
    SocialAuthService.prototype.connect = function (serviceName) {
        return this.openNewSocialAuthWindow('secure/auth/social/' + serviceName + '/connect');
    };
    /**
     * Disconnect specified social account from current user.
     */
    SocialAuthService.prototype.disconnect = function (serviceName) {
        return this.httpClient.post('auth/social/' + serviceName + '/disconnect');
    };
    /**
     * Handle social login callback, based on returned status.
     */
    SocialAuthService.prototype.socialLoginCallback = function (status, data) {
        if (data === void 0) { data = null; }
        if (!status)
            return;
        switch (status.toUpperCase()) {
            case 'SUCCESS':
                this.currentUser.assignCurrent(data['user']);
                this.router.navigate([this.auth.getRedirectUri()]);
                break;
            case 'SUCCESS_CONNECTED':
                if (this.resolve)
                    this.resolve(data.user);
                break;
            case 'ALREADY_LOGGED_IN':
                this.router.navigate([this.auth.getRedirectUri()]);
                break;
            case 'REQUEST_EXTRA_CREDENTIALS':
                this.showRequestExtraCredentialsModal({ credentials: data });
                break;
            case 'ERROR':
                var message = data ? data : this.i18n.t('An error occurred. Please try again later');
                this.toast.open(message, { duration: 6000 });
        }
    };
    /**
     * Open extra credentials modal and subscribe to modal events.
     */
    SocialAuthService.prototype.showRequestExtraCredentialsModal = function (config) {
        var _this = this;
        this.extraCredentialsModal = this.modal.open(RequestExtraCredentialsModalComponent, config);
        this.extraCredentialsModal.componentInstance.onSubmit$.subscribe(function (credentials) {
            if (!credentials)
                return;
            _this.sendExtraCredentialsToBackend(credentials);
        });
    };
    /**
     * Send specified credentials to backend and handle success/error.
     */
    SocialAuthService.prototype.sendExtraCredentialsToBackend = function (data) {
        var _this = this;
        this.httpClient.post('auth/social/extra-credentials', data).subscribe(function (response) {
            _this.currentUser.assignCurrent(response['data']);
            _this.extraCredentialsModal.close();
            _this.router.navigate([_this.auth.getRedirectUri()]).then(function () {
                _this.toast.open('Accounts connected');
            });
        }, this.extraCredentialsModal.componentInstance.handleErrors.bind(this.extraCredentialsModal.componentInstance));
    };
    /**
     * Open new browser window with given url.
     */
    SocialAuthService.prototype.openNewSocialAuthWindow = function (url) {
        var _this = this;
        var left = (screen.width / 2) - (this.windowWidth / 2);
        var top = (screen.height / 2) - (this.windowHeight / 2);
        return new Promise(function (resolve) {
            _this.resolve = resolve;
            window.open(url, 'Authenticate Account', 'menubar=0, location=0, toolbar=0, titlebar=0, status=0, scrollbars=1, width=' + _this.windowWidth
                + ', height=' + _this.windowHeight + ', ' + 'left=' + left + ', top=' + top);
        });
    };
    /**
     * Listen for "message" event from social login popup
     * window and call "socialLoginCallback" once received
     */
    SocialAuthService.prototype.listenForMessageFromPopup = function () {
        var _this = this;
        window.addEventListener('message', function (e) {
            if (e.data.type !== 'social-auth' || _this.settings.getBaseUrl().indexOf(e.origin) === -1)
                return;
            _this.socialLoginCallback(e.data.status, e.data['callbackData']);
        }, false);
    };
    SocialAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocialAuthService_Factory() { return new SocialAuthService(i0.ɵɵinject(i1.AppHttpClient), i0.ɵɵinject(i2.CurrentUser), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.Settings), i0.ɵɵinject(i5.Toast), i0.ɵɵinject(i6.AuthService), i0.ɵɵinject(i7.Translations), i0.ɵɵinject(i8.Modal)); }, token: SocialAuthService, providedIn: "root" });
    return SocialAuthService;
}());
export { SocialAuthService };
