import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { VideoService } from '../video.service';
import { finalize } from 'rxjs/operators';
import { MESSAGES } from '../../../toast-messages';
import { UploadQueueService } from '@common/uploads/upload-queue/upload-queue.service';
import { Toast } from '@common/core/ui/toast.service';
import { Settings } from '@common/core/config/settings.service';
import { openUploadWindow } from '@common/uploads/utils/open-upload-window';
import { UploadInputTypes } from '@common/uploads/upload-input-config';
import { ValueLists } from '@common/core/services/value-lists.service';
import { Router } from '@angular/router';
var CrupdateVideoModalComponent = /** @class */ (function () {
    function CrupdateVideoModalComponent(fb, videos, toast, uploadQueue, valueLists, settings, router, dialogRef, data) {
        this.fb = fb;
        this.videos = videos;
        this.toast = toast;
        this.uploadQueue = uploadQueue;
        this.valueLists = valueLists;
        this.settings = settings;
        this.router = router;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading$ = new BehaviorSubject(false);
        this.languages$ = new BehaviorSubject([]);
        this.episodeCount$ = new BehaviorSubject([]);
        this.videoForm = this.fb.group({
            name: [],
            thumbnail: [],
            url: [],
            quality: ['hd'],
            type: ['video'],
            category: ['trailer'],
            title: [],
            title_id: [],
            season: [],
            episode: [],
            language: ['en'],
            order: [0],
        });
    }
    CrupdateVideoModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hydrateForm();
        this.valueLists.get(['languages']).subscribe(function (response) {
            _this.languages$.next(response.languages);
        });
    };
    CrupdateVideoModalComponent.prototype.confirm = function () {
        this.loading$.next(true);
        if (this.data.video) {
            this.updateVideo();
        }
        else {
            this.createVideo();
        }
    };
    CrupdateVideoModalComponent.prototype.getPayload = function () {
        var payload = this.videoForm.value;
        if (payload.title) {
            payload.title_id = payload.title.id;
            delete payload.title;
        }
        return payload;
    };
    CrupdateVideoModalComponent.prototype.createVideo = function () {
        var _this = this;
        this.videos.create(this.getPayload())
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            _this.toast.open(MESSAGES.VIDEO_CREATE_SUCCESS);
            _this.close(response.video);
        }, function () {
            _this.toast.open(MESSAGES.VIDEO_CREATE_FAILED);
        });
    };
    CrupdateVideoModalComponent.prototype.updateVideo = function () {
        var _this = this;
        this.videos.update(this.data.video.id, this.getPayload())
            .pipe(finalize(function () { return _this.loading$.next(false); }))
            .subscribe(function (response) {
            _this.toast.open(MESSAGES.VIDEO_UPDATE_SUCCESS);
            _this.close(response.video);
        }, function () {
            _this.toast.open(MESSAGES.VIDEO_UPDATE_FAILED);
        });
    };
    CrupdateVideoModalComponent.prototype.close = function (video) {
        this.dialogRef.close(video);
    };
    CrupdateVideoModalComponent.prototype.uploadFile = function (type) {
        var _this = this;
        openUploadWindow({ types: [UploadInputTypes[type]] }).then(function (upload) {
            var params = {
                uri: "uploads/" + type + "s",
                httpParams: {
                    path: type === 'image' ? 'media-images/videos' : 'title-videos'
                },
            };
            _this.uploadQueue.start(upload, params).subscribe(function (response) {
                var _a;
                var prop = type === 'image' ? 'thumbnail' : 'url';
                _this.videoForm.patchValue((_a = {},
                    _a[prop] = _this.settings.getBaseUrl(true) + response.fileEntry.url,
                    _a));
                if (type === 'video') {
                    _this.videoForm.patchValue({ type: 'video' });
                }
            });
        });
    };
    CrupdateVideoModalComponent.prototype.getIterableFromNumber = function (number) {
        return Array.from(new Array(number), function (v, i) { return i + 1; });
    };
    CrupdateVideoModalComponent.prototype.isSeries = function () {
        return this.data.title && this.data.title.is_series;
    };
    CrupdateVideoModalComponent.prototype.hydrateForm = function () {
        var _this = this;
        // update episode count, when season number changes
        this.videoForm.get('season').valueChanges.subscribe(function (number) {
            _this.episodeCount$.next(_this.getEpisodeCountForSeason(number));
        });
        // set specified video
        if (this.data.video) {
            this.videoForm.patchValue(this.data.video);
        }
        if (this.data.title) {
            this.videoForm.patchValue({ title: this.data.title });
        }
        // hydrate season and episode number, if media item is series
        if (this.isSeries() && !this.data.video && !this.videoForm.value.season) {
            this.videoForm.patchValue({
                season: this.data.season || this.getFirstSeasonNumber(),
                episode: this.data.episode || 1
            });
        }
    };
    CrupdateVideoModalComponent.prototype.getEpisodeCountForSeason = function (seasonNum) {
        var episodeCount = 24;
        if (this.data.title) {
            var season = this.data.title.seasons ? this.data.title.seasons.find(function (s) { return s.number === seasonNum; }) : null;
            if (season) {
                episodeCount = season.episodes && season.episodes.length ? season.episodes.length : season.episode_count;
            }
        }
        return this.getIterableFromNumber(episodeCount);
    };
    CrupdateVideoModalComponent.prototype.getFirstSeasonNumber = function () {
        var title = this.data.title;
        if (title.seasons && title.seasons.length) {
            return title.seasons[0].number;
        }
        else {
            return 1;
        }
    };
    CrupdateVideoModalComponent.prototype.insideAdmin = function () {
        return this.router.url.indexOf('admin') > -1;
    };
    return CrupdateVideoModalComponent;
}());
export { CrupdateVideoModalComponent };
